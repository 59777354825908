import { useLocation } from '@reach/router'
import PuxLink from 'Shared/utils/PuxLink'
import React, { FunctionComponent } from 'react'
import { PuxAnchorLink } from 'Root/Shared/utils/PuxAnchorLink/PuxAnchorLink'
import { getModalTriggerId } from 'Shared/components/PuxModal/PuxModal'
import { getLocalizedUrl } from 'Shared/utils/localeURL'

export type PuxButtonStyle =
  | `Button--primary`
  | `Button--secondary`
  | `Button--link`
export type PuxButtonSize = `Button--large` | `Button--medium` | `Button--small`
export type PuxButtonColor = `Button--yellow` | `Button--blue` | `Button--white`

export interface PuxButtonType {
  buttonType: PuxButtonStyle
  buttonSize: PuxButtonSize
  buttonColor: PuxButtonColor
  buttonDisabled?: boolean
  buttonNewWindow?: boolean
  buttonOpenModalId?: string
  buttonLink: {
    url: [string]
    internal: [boolean]
    text: [string]
  }
}

const PuxButton: FunctionComponent<PuxButtonType> = (props) => {
  const target = props.buttonNewWindow ? `_blank` : undefined
  const url = props.buttonLink?.url?.length && props.buttonLink.url[0]
  const text = props.buttonLink?.text?.length && props.buttonLink.text[0]
  const isInternal = props.buttonLink?.internal && props.buttonLink.internal[0]
  const classes: string[] = [`Button`]

  if (props.buttonType) {
    classes.push(props.buttonType)
  }

  if (props.buttonSize) {
    classes.push(props.buttonSize)
  }

  if (props.buttonColor) {
    classes.push(props.buttonColor)
  }

  if (props.buttonDisabled) {
    classes.push(`is-disabled`)
  }

  const openModal = (event) => {
    event.preventDefault()
    const trigger = document.getElementById(
      getModalTriggerId(props.buttonOpenModalId)
    )
    if (trigger) {
      trigger.click()
    } else {
      console.log(
        `Modal error: Section-modal with ID "${props.buttonOpenModalId}" not found`
      )
    }
  }

  if (!url && !text) return null

  if (url && isInternal) {
    return (
      <PuxLink
        className={classes.join(` `)}
        to={`${getLocalizedUrl(url)}`}
        target={target}
        onClick={props.buttonOpenModalId ? openModal : undefined}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </PuxLink>
    )
  }

  if (url && !isInternal && !url.includes(`#`)) {
    return (
      <a
        className={classes.join(` `)}
        href={url}
        target={target}
        rel={target === `_blank` ? `noopener` : undefined}
        onClick={props.buttonOpenModalId ? openModal : undefined}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </a>
    )
  }

  if (url && !isInternal && url.includes(`#`)) {
    const location = useLocation()
    const pathname = location.pathname
    const anchorPath = pathname + url

    return (
      <PuxAnchorLink
        className={classes.join(` `)}
        to={anchorPath.replace('//#', '/#')}
        data-open-modal-id={props.buttonOpenModalId}
      >
        {text}
      </PuxAnchorLink>
    )
  }

  return (
    <button
      type={`button`}
      className={classes.join(` `)}
      onClick={props.buttonOpenModalId ? openModal : undefined}
      data-open-modal-id={props.buttonOpenModalId}
    >
      {text}
    </button>
  )
}

export default PuxButton
