import "./EasySoftwareHeroBannerItemText.scss";

import React, { FunctionComponent } from "react";
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from "Shared/components/PuxWysiwyg/PuxWysiwyg";
import PuxButton, { PuxButtonType } from "../PuxButton/PuxButton";
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from "Root/Shared/components/content/mediaImage/PuxMediaSelector";

export interface EasySoftwareHeroBannerItemTextType {
  contentItemId: string;
  contentType: string;
  displayText: string;
  heroBannerItemTextBackgroundImage: PuxMediaSelectorType;
  heroBannerItemTextContent: PuxWysiwygHtmlBodyType;
  heroBannerItemTextButton1: PuxButtonType;
  heroBannerItemTextButton2: PuxButtonType;
}

export interface EasySoftwareHeroBannerItemTextProps {
  data: EasySoftwareHeroBannerItemTextType;
}

const EasySoftwareHeroBannerItemText: FunctionComponent<
  EasySoftwareHeroBannerItemTextProps
> = (props) => {
  const primaryButton = props.data.heroBannerItemTextButton1,
    secondaryButton = props.data.heroBannerItemTextButton2;

  return (
    <div className={`HeroBannerItem HeroBannerItem--onlyText `}>
          <PuxMediaSelector
      {...props.data.heroBannerItemTextBackgroundImage}
      width={1920}
      height={600}
      disableLazyload={true}
    />
      <div className="HeroBannerItem-text">
        <PuxWysiwyg content={props.data.heroBannerItemTextContent} />
        <div className="HeroBannerItem-buttons">
          {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
          {secondaryButton?.buttonLink.text && (
            <PuxButton {...secondaryButton} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EasySoftwareHeroBannerItemText;
