import './FunctionPart.scss'

import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import useWindowDimensions from 'Root/Shared/utils/useWindowDimensions'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import PuxVideo, { PuxVideoType } from 'Shared/components/PuxVideo/PuxVideo'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { t } from 'ttag'

import PuxLightboxGallery from '../../PuxLightboxGallery/PuxLightboxGallery'

export interface FunctionPartType {
  contentType: 'EasySoftwareFunction'
  contentItemId: string
  path: string
  displayText: string
  functionPerex: PuxWysiwygHtmlBodyType
  functionContent: PuxWysiwygHtmlBodyType
  functionImage: PuxMediaSelectorType
  functionGallery: PuxMediaSelectorType
  puxVideo: PuxVideoType
}

const FunctionPart: FunctionComponent<FunctionPartType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  const { width } = useWindowDimensions()

  const ImageContentGallery = (
    <PuxLightboxGallery
      {...props.functionGallery}
      disableLazyload
      width={160}
      height={80}
    />
  )

  const thumbnail =
    props.puxVideo?.videoThumbnail?.resizePaths?.length > 0
      ? props.puxVideo?.videoThumbnail
      : props.functionImage.mediaSelectorImage

  return (
    <div className={`Function`}>
      <div className='Function-image'>
        <div className='Function-mainImage'>
          {!props.puxVideo?.videoYTID?.html && (
            <PuxMediaSelector
              {...props.functionImage}
              width={720}
              height={400}
            />
          )}
          {props.puxVideo?.videoYTID?.html && (
            <PuxVideo
              videoStructuredDataContentUrl={
                props.puxVideo?.videoStructuredDataContentUrl
              }
              videoStructuredDataDescription={
                props.puxVideo?.videoStructuredDataDescription
              }
              videoStructuredDataName={props.puxVideo?.videoStructuredDataName}
              videoStructuredDataUploadDate={
                props.puxVideo?.videoStructuredDataUploadDate
              }
              videoThumbnail={thumbnail}
              videoYTID={props.puxVideo?.videoYTID}
            />
          )}
          {width > 767 && (
            <Collapse isOpened={isOpened}>{ImageContentGallery}</Collapse>
          )}
        </div>
      </div>
      <div className='Function-content'>
        {props.displayText && <h3>{props.displayText}</h3>}
        <PuxWysiwyg content={props.functionPerex} />
        {(props.functionContent?.html ||
          props.functionGallery?.mediaSelectorImage?.resizePaths[0]) && (
            <>
              <Collapse
                initialStyle={{ height: `0px`, overflow: `hidden` }}
                isOpened={isOpened}
              >
                {props.functionContent?.html && (
                  <PuxWysiwyg content={props.functionContent} />
                )}
                {width < 768 && ImageContentGallery}
              </Collapse>
              <div className={`Function-moreLess`}>
                <button
                  className={`Button Button--link Button--medium${isOpened ? ` is-active` : ``
                    }`}
                  onClick={() => setIsOpened(!isOpened)}
                >
                  {isOpened
                    ? t`Redmine.Function.LessInfo`
                    : t`Redmine.Function.MoreInfo`}
                </button>
              </div>
            </>
          )}
      </div>
    </div>
  )
}

export default FunctionPart
