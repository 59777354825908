import './EasySoftwareHeroBannerItem.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'

export interface EasySoftwareHeroBannerItemPictureType {
  contentItemId: string
  contentType: string
  displayText: string
  heroBannerItemPictureContent: PuxWysiwygHtmlBodyType
  heroBannerItemPictureButton1: PuxButtonType
  heroBannerItemPictureButton2: PuxButtonType
  puxMediaSelector: PuxMediaSelectorType
  easySoftwareHeroBannerPosition: {
    heroBannerPositionMobile: string
    heroBannerPositionDesktop: string
    heroBannerPositionMobileCenterContent: boolean
  }
}

export interface EasySoftwareHeroBannerItemPictureProps {
  data: EasySoftwareHeroBannerItemPictureType
}

export type HeroBannerPositionDesktop = `HeroBannerItem--desktopReversed`
export type HeroBannerPositionMobile = `HeroBannerItem--mobileReversed`

const EasySoftwareHeroBannerItemPicture: FunctionComponent<EasySoftwareHeroBannerItemPictureProps> = (
  props
) => {
  const primaryButton = props.data.heroBannerItemPictureButton1,
    secondaryButton = props.data.heroBannerItemPictureButton2,
    classes = [`HeroBannerItem`]

  if (props.data.easySoftwareHeroBannerPosition.heroBannerPositionMobile) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionMobile
    )
  }
  if (props.data.easySoftwareHeroBannerPosition.heroBannerPositionDesktop) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionDesktop
    )
  }
  if (
    props.data.easySoftwareHeroBannerPosition
      .heroBannerPositionMobileCenterContent
  ) {
    classes.push(`HeroBannerItem--mobileCenterContent`)
  }

  return (
    <div className={classes.join(` `)}>
      <div className='HeroBannerItem-image'>
        <PuxMediaSelector
          {...props.data.puxMediaSelector}
          width={800}
          height={470}
          disableLazyload={true}
        />
      </div>
      <div className='HeroBannerItem-text'>
        <PuxWysiwyg content={props.data.heroBannerItemPictureContent} />
        <div className='HeroBannerItem-buttons'>
          {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
          {secondaryButton?.buttonLink.text && (
            <PuxButton {...secondaryButton} />
          )}
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareHeroBannerItemPicture
