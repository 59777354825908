import './EasySoftwareTimeline.scss'

import React, { FunctionComponent } from 'react'

export interface TimelineItemType {
  displayText: string
  timelineItemContent: string
}

export interface EasySoftwareTimelineType {
  displayText: string
  timelineItems: {
    contentItems: [TimelineItemType]
  }
}

const EasySoftwareTimeline: FunctionComponent<EasySoftwareTimelineType> = (
  props
) => (
  <div className='Timeline'>
    <div className='Timeline-items'>
      {props.timelineItems &&
        props.timelineItems.contentItems &&
        props.timelineItems.contentItems.map((item, idx) => (
          <div className='TimelineItem' key={idx}>
            <h3 className='TimelineItem-year'>{item.displayText}</h3>
            <span className='TimelineItem-marker'>
              <span className='TimelineItem-marker-inner'>
                <span className='TimelineItem-marker-center'></span>
              </span>
            </span>
            <p className='TimelineItem-content'>{item.timelineItemContent}</p>
          </div>
        ))}
    </div>
  </div>
)

export default EasySoftwareTimeline
