import './EasySoftwareHeroBannerItem.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'

export interface EasySoftwareHeroBannerItemVideoType {
  contentItemId: string
  contentType: string
  displayText: string
  heroBannerItemVideoContent: PuxWysiwygHtmlBodyType
  heroBannerItemVideoButton1: PuxButtonType
  heroBannerItemVideoButton2: PuxButtonType
  puxMediaSelector: PuxMediaSelectorType
  easySoftwareHeroBannerPosition: {
    heroBannerPositionMobile: string
    heroBannerPositionDesktop: string
    heroBannerPositionMobileCenterContent: boolean
  }
  puxVideo: PuxVideoType
}

export interface EasySoftwareHeroBannerItemVideoProps {
  data: EasySoftwareHeroBannerItemVideoType
}

export type HeroBannerPositionDesktop = `HeroBannerItem--desktopReversed`
export type HeroBannerPositionMobile = `HeroBannerItem--mobileReversed`

const EasySoftwareHeroBannerItemVideo: FunctionComponent<EasySoftwareHeroBannerItemVideoProps> = (
  props
) => {
  const primaryButton = props.data.heroBannerItemVideoButton1,
    secondaryButton = props.data.heroBannerItemVideoButton2,
    classes = [`HeroBannerItem`]

  if (props.data?.easySoftwareHeroBannerPosition?.heroBannerPositionMobile) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionMobile
    )
  }
  if (props.data?.easySoftwareHeroBannerPosition?.heroBannerPositionDesktop) {
    classes.push(
      props.data.easySoftwareHeroBannerPosition.heroBannerPositionDesktop
    )
  }
  if (
    props.data?.easySoftwareHeroBannerPosition
      ?.heroBannerPositionMobileCenterContent
  ) {
    classes.push(`HeroBannerItem--mobileCenterContent`)
  }

  return (
    <div className={classes.join(` `)}>
      <div className='HeroBannerItem-image'>
        {props.data.puxVideo?.videoYTID?.html && (
          <PuxVideo
            videoStructuredDataContentUrl={
              props.data.puxVideo?.videoStructuredDataContentUrl
            }
            videoStructuredDataDescription={
              props.data.puxVideo?.videoStructuredDataDescription
            }
            videoStructuredDataName={
              props.data.puxVideo?.videoStructuredDataName
            }
            videoStructuredDataUploadDate={
              props.data.puxVideo?.videoStructuredDataUploadDate
            }
            videoThumbnail={props.data.puxVideo?.videoThumbnail}
            videoYTID={props.data.puxVideo?.videoYTID}
          />
        )}
      </div>
      <div className='HeroBannerItem-text'>
        <PuxWysiwyg content={props.data.heroBannerItemVideoContent} />
        <div className='HeroBannerItem-buttons'>
          {primaryButton?.buttonLink.text && <PuxButton {...primaryButton} />}
          {secondaryButton?.buttonLink.text && (
            <PuxButton {...secondaryButton} />
          )}
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareHeroBannerItemVideo
