import PuxLink from 'Shared/utils/PuxLink'
import React from 'react'
import PuxSocials from 'Shared/components/PuxSocials/PuxSocials'
import { t } from 'ttag'
import { ReactComponent as Logo } from '../../svg/ES-logo.svg'

const Footer: React.FunctionComponent = () => {
  const year = `${new Date().getFullYear()}`

  return (
    <footer className='Footer'>
      <div className='Container'>
        <div className='FooterBrand'>
          <div className='FooterLogo'>
            <Logo />
          </div>
          <div className='FooterSocial'>
          </div>
          <div className='FooterPartners'>
            <PuxSocials
              urlFacebook='https://www.facebook.com/easysoftwarecom/'
              urlLinkedin='https://www.linkedin.com/company/easy-software-ltd./'
            />
          </div>
        </div>
        <div className='Footer-foot'>
          <div className='Footer-copyright'>
            {t`Redmine.Footer.Copyright`.replace(`%currentYear%`, year)}
            <span className='Footer-sitemap'>
              <PuxLink to={`/sitemap.xml`}>{t`Redmine.Footer.SiteMap`}, XML</PuxLink>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
