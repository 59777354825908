import React, { FunctionComponent, lazy, Suspense } from 'react'
import { EasySoftwareSideCardCustomType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardCustom'
import { EasySoftwareSideCardNavigationType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import { EasySoftwareSideCardNewsletterType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNewsletter'
import { EasySoftwareSideCardTrialType } from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardTrial'
import { WidgetEasySoftwareBenefitsType } from 'Shared/components/widgets/WidgetEasySoftwareBenefits'
import { WidgetEasySoftwareCardsType } from 'Shared/components/widgets/WidgetEasySoftwareCards'
import { WidgetEasySoftwareCaseStudiesType } from 'Shared/components/widgets/WidgetEasySoftwareCaseStudies'
import { WidgetEasySoftwareEmbeddedCodeContainerType } from 'Shared/components/widgets/WidgetEasySoftwareEmbeddedCodeContainer'
import { WidgetEasySoftwareFeaturesType } from 'Shared/components/widgets/WidgetEasySoftwareFeatures'
import { WidgetEasySoftwareHeroBannerContainerType } from 'Shared/components/widgets/WidgetEasySoftwareHeroBannerContainer'
import { WidgetEasySoftwareIconBarType } from 'Shared/components/widgets/WidgetEasySoftwareIconBar'
import { WidgetEasySoftwareModulesType } from 'Shared/components/widgets/WidgetEasySoftwareModules'
import { WidgetEasySoftwareNavigatorType } from 'Shared/components/widgets/WidgetEasySoftwareNavigator'
import { WidgetEasySoftwareReviewsType } from 'Shared/components/widgets/WidgetEasySoftwareReviews'
import { WidgetEasySoftwareSecondaryNavigationType } from 'Shared/components/widgets/WidgetEasySoftwareSecondaryNavigation'
import { WidgetEasySoftwareSolutionsType } from 'Shared/components/widgets/WidgetEasySoftwareSolutions'
import { WidgetEasySoftwareTableType } from 'Shared/components/widgets/WidgetEasySoftwareTable'
import { WidgetEasySoftwareTestimonialsType } from 'Shared/components/widgets/WidgetEasySoftwareTestimonials'
import { WidgetEasySoftwareTimelineType } from 'Shared/components/widgets/WidgetEasySoftwareTimeline'
import { WidgetPuxGalleryType } from 'Shared/components/widgets/WidgetPuxGallery'
import { WidgetPuxGalleryWrapperType } from 'Shared/components/widgets/WidgetPuxGalleryWrapper'
import { WidgetPuxImageContentType } from 'Shared/components/widgets/WidgetPuxImageContent'
import { WidgetPuxLinkGalleryWrapperType } from 'Shared/components/widgets/WidgetPuxLinkGalleryWrapper'
import { WidgetPuxNumbersContainerType } from 'Shared/components/widgets/WidgetPuxNumbersContainer'
import { WidgetPuxRepeaterType } from 'Shared/components/widgets/WidgetPuxRepeater'
import WidgetSection, {
  WidgetPuxSectionType,
} from 'Shared/components/widgets/WidgetPuxSection'
import WidgetPuxTopImage, {
  WidgetPuxTopImageType,
} from 'Shared/components/widgets/WidgetPuxTopImage'
import { WidgetPuxVideoContainerType } from 'Shared/components/widgets/WidgetPuxVideoContainer'
import { WidgetPuxWysiwygType } from 'Shared/components/widgets/WidgetPuxWysiwyg'
import { WidgetEasySoftwareAccordionType } from 'Root/Shared/components/widgets/WidgetEasySoftwareAccordion'
import { WidgetEasySoftwareTabsType } from 'Root/Shared/components/widgets/WidgetEasySoftwareTabs'

const WidgetPuxImageContent = lazy(
  () => import(`Shared/components/widgets/WidgetPuxImageContent`)
)
const WidgetEasySoftwareCaseStudies = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareCaseStudies`)
)
const EasySoftwareSideCardCustom = lazy(
  () =>
    import(`Shared/components/EasySoftwareSideCard/EasySoftwareSideCardCustom`)
)
const EasySoftwareSideCardNavigation = lazy(
  () =>
    import(
      `Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation`
    )
)
const EasySoftwareSideCardNewsletter = lazy(
  () =>
    import(
      `Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNewsletter`
    )
)
const EasySoftwareSideCardTrial = lazy(
  () =>
    import(`Shared/components/EasySoftwareSideCard/EasySoftwareSideCardTrial`)
)
const WidgetEasySoftwareBenefits = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareBenefits`)
)
const WidgetEasySoftwareHeroBannerContainer = lazy(
  () =>
    import(`Shared/components/widgets/WidgetEasySoftwareHeroBannerContainer`)
)
const WidgetEasySoftwareCards = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareCards`)
)
const WidgetEasySoftwareEmbeddedCodeContainer = lazy(
  () =>
    import(`Shared/components/widgets/WidgetEasySoftwareEmbeddedCodeContainer`)
)
const WidgetEasySoftwareFeatures = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareFeatures`)
)
const WidgetEasySoftwareIconBar = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareIconBar`)
)
const WidgetEasySoftwareModules = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareModules`)
)
const WidgetEasySoftwareNavigator = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareNavigator`)
)
const WidgetEasySoftwareReviews = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareReviews`)
)
const WidgetEasySoftwareSolutions = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareSolutions`)
)
const WidgetEasySoftwareTable = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTable`)
)
const WidgetEasySoftwareTestimonials = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTestimonials`)
)
const WidgetEasySoftwareTimeline = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareTimeline`)
)
const WidgetPuxGallery = lazy(
  () => import(`Shared/components/widgets/WidgetPuxGallery`)
)
const WidgetPuxGalleryWrapper = lazy(
  () => import(`Shared/components/widgets/WidgetPuxGalleryWrapper`)
)

const WidgetPuxLinkGalleryWrapper = lazy(
  () => import(`Shared/components/widgets/WidgetPuxLinkGalleryWrapper`)
)

const WidgetPuxNumbersContainer = lazy(
  () => import(`Shared/components/widgets/WidgetPuxNumbersContainer`)
)
const WidgetPuxRepeater = lazy(
  () => import(`Shared/components/widgets/WidgetPuxRepeater`)
)
const WidgetPuxVideoContainer = lazy(
  () => import(`Shared/components/widgets/WidgetPuxVideoContainer`)
)
const WidgetPuxWysiwyg = lazy(
  () => import(`Shared/components/widgets/WidgetPuxWysiwyg`)
)
const WidgetEasySoftwareSecondaryNavigation = lazy(
  () => import(`Shared/components/widgets/WidgetEasySoftwareSecondaryNavigation`)
)
const WidgetEasySoftwareAccordion = lazy(
  () => import(`Root/Shared/components/widgets/WidgetEasySoftwareAccordion`)
)

const WidgetEasySoftwareTabs = lazy(
  () => import(`Root/Shared/components/widgets/WidgetEasySoftwareTabs`)
)

export type WidgetData =
  | WidgetPuxSectionType
  | WidgetPuxRepeaterType
  | WidgetPuxWysiwygType
  | WidgetEasySoftwareBenefitsType
  | WidgetEasySoftwareHeroBannerContainerType
  | WidgetEasySoftwareCardsType
  | WidgetEasySoftwareCaseStudiesType
  | WidgetEasySoftwareFeaturesType
  | WidgetEasySoftwareTableType
  | WidgetEasySoftwareNavigatorType
  | WidgetPuxGalleryType
  | WidgetPuxGalleryWrapperType
  | WidgetPuxLinkGalleryWrapperType
  | WidgetEasySoftwareReviewsType
  | WidgetEasySoftwareSolutionsType
  | WidgetPuxImageContentType
  | WidgetPuxNumbersContainerType
  | WidgetPuxTopImageType
  | WidgetPuxVideoContainerType
  | EasySoftwareSideCardNewsletterType
  | EasySoftwareSideCardCustomType
  | EasySoftwareSideCardTrialType
  | EasySoftwareSideCardNavigationType
  | WidgetEasySoftwareIconBarType
  | WidgetEasySoftwareEmbeddedCodeContainerType
  | WidgetEasySoftwareTimelineType
  | WidgetEasySoftwareModulesType
  | WidgetEasySoftwareTestimonialsType
  | WidgetEasySoftwareSecondaryNavigationType
  | WidgetEasySoftwareAccordionType
  | WidgetEasySoftwareTabsType

export interface WidgetPageContextData {
  [widgetZone: string]: [WidgetData]
}

export interface WidgetMetadata {
  alignment: string
  size: number
}

export interface WidgetProperitesPart {
  widgetPropertiesPartID: string
  widgetPropertiesPartMarginBottom: string
  widgetPropertiesPartMarginTop: string
  widgetPropertiesPartPaddingBottom: string
  widgetPropertiesPartPaddingTop: string
  widgetPropertiesPartMobileOrder: string
}

export interface WidgetAnimationPart {
  widgetAnimationPartIsEnabled: boolean
  widgetAnimationPartType: string
}

export interface WidgetBase {
  contentItemId?: string
  metadata: WidgetMetadata
  puxWidgetProperties: WidgetProperitesPart
  puxWidgetAnimation: WidgetAnimationPart
}

export interface WidgetBuilderProps {
  widgetBuilderData: [WidgetData]
}

const buildWidget = (widgetData: WidgetData, id: number) => {
  if (!widgetData?.contentType) {
    console.log(`denied by widgetbuilder`, widgetData)
    return
  }
  switch (widgetData.contentType) {
    case `PuxSection`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetSection {...widgetData} />
        </Suspense>
      )

    case `PuxSimpleRepeater`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxRepeater {...widgetData} />
        </Suspense>
      )

    case `PuxWysiwyg`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxWysiwyg {...widgetData} />
        </Suspense>
      )

    case `EasySoftwareBenefits`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareBenefits
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareHeroBannerContainer`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareHeroBannerContainer
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareCards`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareCards
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareCaseStudies`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareCaseStudies
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareFeatures`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareFeatures
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareTable`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareTable
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareNavigator`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareNavigator
            {...widgetData}
          />
        </Suspense>
      )

    case `PuxGallery`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxGallery {...widgetData} />
        </Suspense>
      )

    case `PuxGalleryWrapper`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxGalleryWrapper
            {...widgetData}
          />
        </Suspense>
      )

    case `PuxLinkGalleryWrapper`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxLinkGalleryWrapper
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareReviews`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareReviews
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSolutions`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareSolutions
            {...widgetData}
          />
        </Suspense>
      )

    case `PuxNumbersContainer`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxNumbersContainer
            {...widgetData}
          />
        </Suspense>
      )

    case `PuxImageContent`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxImageContent
            {...widgetData}
          />
        </Suspense>
      )

    case `PuxTopImage`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxTopImage {...widgetData} />
        </Suspense>
      )

    case `PuxVideoContainer`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetPuxVideoContainer
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSideCardNewsletter`:
      return (
        <Suspense key={id} fallback={<></>}>
          <EasySoftwareSideCardNewsletter
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSideCardTrial`:
      return (
        <Suspense key={id} fallback={<></>}>
          <EasySoftwareSideCardTrial
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSideCardCustom`:
      return (
        <Suspense key={id} fallback={<></>}>
          <EasySoftwareSideCardCustom
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSideCardNavigation`:
      return (
        <Suspense key={id} fallback={<></>}>
          <EasySoftwareSideCardNavigation
            {...widgetData}
            type='widget'
          />
        </Suspense>
      )

    case `EasySoftwareIconBar`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareIconBar
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareEmbeddedCodeContainer`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareEmbeddedCodeContainer
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareTimeline`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareTimeline
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareModules`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareModules
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareTestimonials`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareTestimonials
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareSecondaryNavigation`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareSecondaryNavigation
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareAccordion`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareAccordion
            {...widgetData}
          />
        </Suspense>
      )

    case `EasySoftwareTabs`:
      return (
        <Suspense key={id} fallback={<></>}>
          <WidgetEasySoftwareTabs
            {...widgetData}
          />
        </Suspense>
      )
  }
}

const WidgetBuilder: FunctionComponent<WidgetBuilderProps> = (props) => {
  const widgetBuilderData = props.widgetBuilderData ?? []
  return (
    <>
      {widgetBuilderData.map((widgetData: WidgetData, idx) =>
        buildWidget(widgetData, idx)
      )}
    </>
  )
}

export default WidgetBuilder
