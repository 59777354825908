import './EasySoftwareModules.scss'

import React, { FunctionComponent } from 'react'
import PuxIconBuilder from 'Shared/components/PuxIconBuilder/PuxIconBuilder'
import { SideCardNavigationLinkType } from 'Shared/components/PuxNavigation/PuxNavigation'
import PuxNavigation from 'Shared/components/PuxNavigation/PuxNavigation'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { t } from 'ttag'

import Circle_1 from '../../../Shared/images/Circle 3.png'
import Circle_2 from '../../../Shared/images/Circle 5.png'
import Laptop from '../../../Shared/images/Clay1.png'

interface EasySoftwareModuleType {
  contentType: 'EasySoftwareModule'
  displayText: string
  moduleCore: PuxWysiwygHtmlBodyType
  moduleIcon: string
  moduleLinks: {
    contentItems: SideCardNavigationLinkType[]
  }
}

export interface EasySoftwareModulesType {
  displayText: string
  modulesItem: {
    cultureContentItems: EasySoftwareModuleType[]
  }
}

const EasySoftwareModules: FunctionComponent<EasySoftwareModulesType> = (
  props
) => {
  const moduleData = props.modulesItem?.cultureContentItems?.length > 0 ? props.modulesItem?.cultureContentItems[0] : null
  if (!moduleData) {
    return null
  }

  return (
    <div className='EsModules'>
      <h2 className='EsModules-title'>{props.displayText}</h2>

      <div className='EsModules-columns'>
        <div className='EsModules-box'>
          <div className='EsModules-col'>
            <p className='EsModules-heading'>{t`Redmine.ModulesWidget.Core`}</p>
            <h4>{moduleData.displayText}</h4>
            <PuxWysiwyg content={moduleData.moduleCore} inline />
          </div>
          <div className='EsModules-images'>
            <img className='EsModules-circle' src={Circle_1} />
            <img className='EsModules-laptop' src={Laptop} />
          </div>
        </div>

        <span className='EsModules-icon'></span>

        <div className='EsModules-navigation'>
          <div className='EsModules-content'>
            <p className='EsModules-heading'>
              {t`Redmine.ModulesWidget.Modules`}
            </p>
            <img className='EsModules-circle' src={Circle_2} />
          </div>
          <PuxNavigation
            type={`widget`}
            items={moduleData.moduleLinks.contentItems}
          />
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareModules
