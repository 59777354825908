import './PuxWysiwyg.scss'

import React, { FunctionComponent, useEffect, useRef } from 'react'
import { getModalTriggerId } from 'Shared/components/PuxModal/PuxModal'

let domWindow: any

/* eslint-disable */
if (typeof window === 'undefined') {
  (async () => {
    // const HappyDom = await import(/* webpackIgnore: true */ `happy-dom`)
    // domWindow = new HappyDom.Window()
  })()
}
/* eslint-enable */

export interface PuxWysiwygType {
  content: PuxWysiwygHtmlBodyType
  wysiwygId?: string
  className?: string
  inline?: boolean
  small?: boolean
}

export interface PuxWysiwygHtmlBodyType {
  html: string
}

const PuxWysiwyg: FunctionComponent<PuxWysiwygType> = (props) => {
  const activeWysiwygRef = useRef<HTMLElement>(null)
  const domModifier = (
    documentInstance: any,
    isVirtualDOM: boolean
  ): string => {
    documentInstance
      .querySelectorAll(`[style*=text-align]`)
      .forEach((element) => {
        const thisElement = (element as unknown) as HTMLElement
        if (thisElement.style.textAlign) {
          switch (thisElement.style.textAlign) {
            case `right`:
              element.classList.add(`Wysiwyg--textRight`)
              break
            case `center`:
              element.classList.add(`Wysiwyg--textCenter`)
              break
            default:
              element.classList.add(`Wysiwyg--textLeft`)
          }
          thisElement.style.textAlign = ``
        }
      })

    documentInstance.querySelectorAll(`a[href]`).forEach((element) => {
      const thisElement = (element as unknown) as HTMLAnchorElement
      const href = thisElement.getAttribute(`href`)

      if (href && href.startsWith(`#`)) {
        return
      }

      if (href && href.includes(`.`)) {
        return
      }
      if (href && !href?.startsWith(`/`)) {
        thisElement.setAttribute(`href`, `/${href}`)
        return
      }

      return
    })

    documentInstance.querySelectorAll(`img`).forEach((element) => {
      const thisElement = (element as unknown) as HTMLImageElement
      thisElement.setAttribute(`loading`, `lazy`)

      return
    })

    documentInstance.querySelectorAll(`iframe`).forEach((element) => {
      const thisElement = (element as unknown) as HTMLImageElement
      thisElement.setAttribute(`loading`, `lazy`)

      return
    })

    if (typeof window === `undefined` && isVirtualDOM) {
      return documentInstance.body.innerHTML
    }

    return ``
  }

  useEffect(() => {
    if (activeWysiwygRef.current) {
      const modalTriggers = activeWysiwygRef.current.querySelectorAll(
        `[href*="#pux-modal-"]`
      )
      modalTriggers.forEach((trigger) => {
        trigger.addEventListener(`click`, (e) => {
          const thisElement = e.target as HTMLAnchorElement
          // Get modal id
          const parts = thisElement.href.split(`#pux-modal-`)
          const modalId = parts[parts.length - 1]
          // Find modal trigger & open modal
          const modalButton = document.querySelector(
            `#${getModalTriggerId(modalId)}`
          ) as HTMLElement
          modalButton?.click()
        })
      })

      domModifier(activeWysiwygRef.current, true)
    }

    if (props.wysiwygId) {
      const thisWrapper = document.querySelector(`#wysiwyg-${props.wysiwygId}`)

      if (thisWrapper) {
        thisWrapper
          .querySelectorAll(`a[href*="#"]:not([href*="#pux-modal-"])`)
          .forEach((element) => {
            element.addEventListener(`click`, (e) => {
              e.preventDefault()
              const thisElement = e.target as HTMLAnchorElement
              const thisAnchor =
                thisElement.getAttribute(`href`)?.split(`#`)[1] ?? null
              if (thisAnchor) {
                document.querySelector(`#${thisAnchor}`)?.scrollIntoView({
                  behavior: `smooth`,
                })
              }
            })
          })
      }
    }
  }, [])

  const classes = [`Wysiwyg`]
  classes.push(props.inline ? `Wysiwyg--inline` : `Wysiwyg--spaced`)
  if (props.className) {
    classes.push(props.className)
  }
  if (props.small) {
    classes.push(`Wysiwyg--small`)
  }

  if (props?.content && props.content?.html) {
    let htmlBody = ``
    htmlBody = props.content.html.replace(
      / src="\/media\//g,
      ` src="${process.env.GATSBY_ORCHARD_API_URL}/media/`
    )

    htmlBody = htmlBody.replace(
      / href="\/media\//g,
      ` href="${process.env.GATSBY_ORCHARD_API_URL}/media/`
    )

    htmlBody = htmlBody
      .replace(/font-size:\s[0-9]{1,}rem[;]{0,1}/g, ``)
      .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
      .replace(/font-family: -apple-system([^">]*)&quot;/g, ``)
      .replace(/pux-button\s/g, `Button Button--large `)
      .replace(/pux-button-filled/g, `Button--primary`)
      .replace(/pux-button-outlined/g, `Button--secondary`)
      .replace(/pux-button-primary/g, `Button--yellow`)
      .replace(/pux-button-secondary/g, `Button--blue`)

    if (domWindow) {
      const domDocument = domWindow.document
      domDocument.body.innerHTML = htmlBody
      htmlBody = domModifier(domDocument, true)
    }

    return (
      <div
        id={props.wysiwygId ? `wysiwyg-${props.wysiwygId}` : ``}
        ref={activeWysiwygRef}
        className={classes.join(` `)}
        dangerouslySetInnerHTML={{
          __html: htmlBody,
        }}
      ></div>
    )
  }

  return null
}

export default PuxWysiwyg
