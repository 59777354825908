import { langConfig, langDomain, localeConfig } from '../../puxconfig'
const activeEnv = (
  process.env.GATSBY_ACTIVE_ENV ||
  process.env.NODE_ENV ||
  `development`
).trim()

export function getFromConfig(
  locale: string,
  configField: 'domains' | 'locale' | 'urlPrefix' | 'isoCode' | 'GTM',
  project: string
): string | langDomain {
  const config = localeConfig[project][locale]
  const field = config[configField] ?? `none`

  if (field !== `none`) {
    return field
  } else {
    console.error(`URL prefix on locale "${locale}" is not defined.`)
    return field
  }
}

export function isSameLocale(url: string): boolean {
  const urlArrey = url.split('/')
  const activePrefix = getFromConfig(process.env.LOCALE!, 'urlPrefix', process.env.PROJECT!)

  if (urlArrey[0] === activePrefix || urlArrey[1] === activePrefix) {
    return true
  }

  return false
}

function extractIsoCode(
  currentUrl: string,
  environment: string,
  locale: string,
  project: string
): string {
  if (!currentUrl || !currentUrl.length) {
    return currentUrl
  }
  // get all iso codes, so we can replace also codes from other languages
  const isoCodes = getIsoCodesWithPrefix(environment, locale, project)
  let cleanedCurrentURL

  if (currentUrl.charAt(0) === `/`) {
    cleanedCurrentURL = currentUrl.replace(`/`, ``)
  } else {
    cleanedCurrentURL = currentUrl
  }

  const currentUrlArray = cleanedCurrentURL.split(`/`)
  const extractedIsoCode = currentUrlArray[0]
  const matchedIsoCode = isoCodes.find((o) => o.isoCode === extractedIsoCode)

  return matchedIsoCode?.isoCode ?? ``
}

function setPrefixToUrl(
  currentUrl: string,
  environment: string,
  locale: string,
  project: string
): string {
  if (!currentUrl || !currentUrl.length || typeof currentUrl !== `string`) {
    return currentUrl
  }
  // get all iso codes, so we can replace also codes from other languages
  const isoCodes = getAllIsoCodesWithPrefix(environment, locale, project)
  let cleanedCurrentURL
  let newUrl = ``

  if (currentUrl.charAt(0) === `/`) {
    cleanedCurrentURL = currentUrl.replace(`/`, ``)
  } else {
    cleanedCurrentURL = currentUrl
  }

  const currentUrlArray = cleanedCurrentURL.split(`/`)
  const extractedIsoCode = currentUrlArray[0]
  const matchedIsoCode = isoCodes.find((o) => o.isoCode === extractedIsoCode)
  // TODO: this has to replace only first occurence, not every
  if (matchedIsoCode) {
    newUrl = cleanedCurrentURL.replace(
      currentUrlArray[0],
      matchedIsoCode.domain +
      (matchedIsoCode.prefix === `` ? `` : `/` + matchedIsoCode.prefix)
    )
  } else {
    newUrl = `/` + cleanedCurrentURL
  }

  return newUrl
}

export function getHrefLang(currentUrl: string): string {
  return extractIsoCode(
    currentUrl,
    activeEnv,
    process.env.LOCALE!,
    process.env.PROJECT!
  )
}

export function getLocalizedUrl(currentUrl: string): string {
  const localizedUrl = setPrefixToUrl(
    currentUrl,
    activeEnv,
    process.env.LOCALE!,
    process.env.PROJECT!
  )

  const forceTrailingSlash = false

  return (
    localizedUrl +
    (forceTrailingSlash && !localizedUrl.endsWith(`/`) ? `/` : ``)
  )
}

export function getLocalizedData(locale: string, project: string): langConfig {
  const localizedConfig = localeConfig[project][locale]
  return localizedConfig
}

export function getDomainsWithCultures(
  environment: string,
  project: string
): [domainCultures] {
  const localeConfigArray = Object.keys(localeConfig[project]).map(
    (key) => localeConfig[project][key]
  )

  const resultArray: [domainCultures] = [
    {
      domain: localeConfigArray[0].domains[environment],
      locales: [localeConfigArray[0].locale],
      isoCodes: [localeConfigArray[0].isoCode],
    },
  ]

  // extract cultures from objects
  localeConfigArray.reduce((previous: langConfig, currentItem) => {
    const groupKey = currentItem.domains[environment]
    let temp = resultArray.find((o) => o.domain === groupKey)
    if (!temp) {
      resultArray.push(
        (temp = {
          domain: groupKey,
          locales: [currentItem.locale],
          isoCodes: [currentItem.isoCode],
        })
      )
    } else {
      temp?.locales.push(currentItem.locale)
      temp?.isoCodes.push(currentItem.isoCode)
    }
    // returns nothing really, everything is written into resultarray objects
    return previous
  })

  return resultArray
}

// returns array of objects mapping isoCode to prefix
function getIsoCodesWithPrefix(
  environment: string,
  locale: string,
  project: string,
  absolutePath?: boolean
): isoCodePrefix[] {
  // extract locales
  const config = localeConfig[project][locale]
  const localeConfigArray = Object.keys(localeConfig[project]).map(
    (key) => localeConfig[project][key]
  )

  const currentLocaleDomain = config?.domains[environment]

  // map them to isoCode / prefix pairs
  const resultArray = localeConfigArray.map<isoCodePrefix>((locale) => {
    if (absolutePath) {
      return {
        locale: locale.locale,
        isoCode: locale.isoCode,
        prefix: locale.urlPrefix,
        domain: locale.domains[environment],
      }
    }

    return {
      locale: locale.locale,
      isoCode: locale.isoCode,
      prefix: locale.urlPrefix,
      domain:
        locale.domains[environment] === currentLocaleDomain // if current locale has same domain as this locale, do not use domain prefix
          ? ``
          : locale.domains[environment],
    }
  })

  return resultArray
}
function getAllIsoCodesWithPrefix(
  environment: string,
  locale: string,
  project: string
): isoCodePrefix[] {
  // get base and AI project names
  const baseProject = project?.endsWith("AI") ? project.replace("AI", "") : project
  const aiProject = project?.endsWith("AI") ? project : project + "AI"

  const resultBaseArray = getIsoCodesWithPrefix(environment, locale, baseProject)
  const resultAIArray = getIsoCodesWithPrefix(environment, locale, aiProject)

  return [...resultBaseArray, ...resultAIArray]
}

// shortcurt variant for use from gatsby directly
export function getIsoCodesWithPrefixPublic(
  absolutePath?: boolean, customProject?: string
): isoCodePrefix[] {
  let project = process.env.PROJECT!

  if (customProject) {
    project = customProject
  }

  if (absolutePath) {
    return getIsoCodesWithPrefix(
      activeEnv,
      process.env.LOCALE!,
      project,
      true
    )
  }

  return getIsoCodesWithPrefix(
    activeEnv,
    process.env.LOCALE!,
    project
  )
}

export interface domainCultures {
  domain: string
  locales: [string]
  isoCodes: [string]
}

export interface isoCodePrefix {
  locale: string
  isoCode: string
  prefix: string
  domain: string
}
