import "./EasySoftwareHeroBannerContainer.scss";

import React, { FunctionComponent } from "react";
import PuxMediaSelector, { PuxMediaSelectorType } from "Root/Shared/components/content/mediaImage/PuxMediaSelector";

import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'
import EasySoftwareBackgroundVideo, { MediaFile, MediaVideoFile } from "../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo";
import EasySoftwareHeroBannerItemAnimation, { EasySoftwareHeroBannerItemAnimationType } from "./EasySoftwareHeroBannerItemAnimation";
import EasySoftwareHeroBannerItemPicture, { EasySoftwareHeroBannerItemPictureType } from "./EasySoftwareHeroBannerItemPicture";
import EasySoftwareHeroBannerItemText, { EasySoftwareHeroBannerItemTextType } from "./EasySoftwareHeroBannerItemText";
import EasySoftwareHeroBannerItemVideo, { EasySoftwareHeroBannerItemVideoType } from "./EasySoftwareHeroBannerItemVideo";
import { MediaSelectorImageType } from "../content/mediaImage/PuxPicture";

// TODO: contentItems: any
export interface EasySoftwareHeroBannerContainerType {
  displayText: string;
  heroBannerContainerTheme: string;
  heroBannerContainerBackground: {
    contentItems: [
      {
        backgroundVideoFileMP4: MediaVideoFile;
        backgroundVideoFileWEBM: MediaVideoFile,
        backgroundVideoPlaceholder: MediaFile,
        heroBannerBackgroundImage: PuxMediaSelectorType,
        displayText: string,
        lottieJSON: string,
        lottieJSONMobile: string | null,
        lottieImage: MediaSelectorImageType | null,
        backgroundVideoLoop: boolean,
        backgroundVideoAutoplay: boolean,
        backgroundVideoControls: boolean,
        backgroundVideoShowOnMobile: boolean
      }
    ]
  };
  heroBannerContainerItems: {
    contentItems: [
      EasySoftwareHeroBannerItemPictureType | EasySoftwareHeroBannerItemTextType | EasySoftwareHeroBannerItemVideoType | EasySoftwareHeroBannerItemAnimationType
    ]
  };
}

export type HeroBannerContainerTheme = `HeroBannerContainer--middleTheme` | `HeroBannerContainer--darkTheme`;

const EasySoftwareHeroBannerContainer: FunctionComponent<EasySoftwareHeroBannerContainerType> = (props) => {

  return (
    <div className={`HeroBannerContainer ${props.heroBannerContainerTheme ?? ``}`}>
      <div className={`HeroBannerContainer-background ` + (props.heroBannerContainerBackground?.contentItems[0]?.lottieJSON ? `HeroBannerContainer-animation` : ``) + (props.heroBannerContainerBackground?.contentItems[0]?.backgroundVideoFileMP4 || props.heroBannerContainerBackground?.contentItems[0]?.backgroundVideoFileWEBM ? `HeroBannerContainer-video` : ``)}>
        {props.heroBannerContainerBackground?.contentItems[0]?.heroBannerBackgroundImage &&
          <PuxMediaSelector
            {...props.heroBannerContainerBackground?.contentItems[0]
              ?.heroBannerBackgroundImage}
            width={1920}
            height={600}
            disableLazyload={true}
          />}
        {props.heroBannerContainerBackground?.contentItems[0]?.lottieJSON &&
          <EasySoftwareLottie
            lottieImage={props.heroBannerContainerBackground?.contentItems[0]?.lottieImage}
            animationData={JSON.parse(props.heroBannerContainerBackground?.contentItems[0]?.lottieJSON)}
            animationDataMobile={props.heroBannerContainerBackground?.contentItems[0]?.lottieJSONMobile ? JSON.parse(props.heroBannerContainerBackground?.contentItems[0]?.lottieJSONMobile) : null} />
        }
        {(props.heroBannerContainerBackground?.contentItems[0]?.backgroundVideoFileMP4 || props.heroBannerContainerBackground?.contentItems[0]?.backgroundVideoFileWEBM) &&
          <EasySoftwareBackgroundVideo {...props.heroBannerContainerBackground?.contentItems[0]} />
        }
      </div>

      {props.heroBannerContainerItems &&
        props.heroBannerContainerItems.contentItems &&
        props.heroBannerContainerItems.contentItems.map((item: any) => {
          const innerClass = `HeroBannerContainer-inner HeroBannerContainer-inner--${item.contentType.replace(`EasySoftwareHeroBannerItem`, ``)}`;
          switch (item.contentType) {
            case `EasySoftwareHeroBannerItemPicture`:
              return (
                <div className={innerClass}>
                  <EasySoftwareHeroBannerItemPicture
                    data={item}
                    key={item.contentItemId}
                  />
                </div>
              );
            case `EasySoftwareHeroBannerItemAnimation`:
              return (
                <div className={innerClass}>
                  <EasySoftwareHeroBannerItemAnimation
                    data={item}
                    key={item.contentItemId}
                  />
                </div>
              );
            case `EasySoftwareHeroBannerItemVideo`:
              return (
                <div className={innerClass}>
                  <EasySoftwareHeroBannerItemVideo
                    data={item}
                    key={item.contentItemId}
                  />
                </div>
              );
            case `EasySoftwareHeroBannerItemText`:
              return (
                <div className={innerClass}>
                  <EasySoftwareHeroBannerItemText
                    data={item}
                    key={item.contentItemId}
                  />
                </div>
              );

            default:
              return ``;
          }
        })}


    </div>
  )
};

export default EasySoftwareHeroBannerContainer;
