import { AnchorItemType } from 'EasySoftwareGatsby/components/core/AnchorsNav'
import React, { FunctionComponent, useState } from 'react'
import { StickyContainer } from 'react-sticky'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { t } from 'ttag'

import Footer from './footer'
import Header from './header'
import EasyInfoBar from 'Root/Shared/components/EasyInfoBar/EasyInfoBar'

interface LayoutPropsType {
  customLayoutClass?: string
  style?: string
  cta?: LinkType
  anchors?: AnchorItemType[]
  localizedPath?: localizedPathType[]
}

const Layout: FunctionComponent<LayoutPropsType> = (props) => {
  const [customHeaderClass, setCustomHeaderClass] = useState(``)
  const getHeaderCustomClass = (duplicatedNav: boolean): void => {
    setCustomHeaderClass(duplicatedNav ? `has-megamenu` : ``)
  }

  const classes = [`layout-main`, props.customLayoutClass, customHeaderClass]

  return (
    <>
      {(t`Redmine.InfoBar.Text` !== `Redmine.InfoBar.Text`) &&
        <EasyInfoBar />
      }
      <StickyContainer>
        <Header
          customHeaderFnc={getHeaderCustomClass}
          customHeaderClass={props.customLayoutClass}
          style={props.style}
          cta={props.cta}
          anchors={props.anchors}
        />
        <main className={classes.join(` `)}>{props.children}</main>
        <Footer />
      </StickyContainer>
    </>
  )
}

export default Layout
