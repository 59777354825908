import './EasySoftwareTabs.scss'

import React, { FunctionComponent, useState } from 'react'

import EasySoftwareTabsItem, { EasySoftwareTabsItemType } from './EasySoftwareTabsItem'

export interface EasySoftwareTabsType {
  tabsContentPositionDesktop: boolean,
  tabsBackground: string,
  bag: {
    contentItems: EasySoftwareTabsItemType[],
  }
}

const EasySoftwareTabs: FunctionComponent<EasySoftwareTabsType> = (
  props
) => {

  const [tabsOpen, setTabsOpen] = useState(0)

  return (
    <div className={`Tabs` + (` Tabs--` + props.tabsBackground)}>
      <div className='Tabs-wrapper'>
        <div className='Tabs-headings'>
          {props.bag &&
            props.bag.contentItems &&
            props.bag.contentItems.map((item, index) => (
              <div className={`Tabs-heading ${index === tabsOpen ? `active` : ``}`} onClick={() => setTabsOpen(index)} key={index}>
                {item.displayText}
              </div>
            ))}
        </div>
      </div>
      <div className={`Tabs-items ` + (props.tabsContentPositionDesktop ? `Tabs--reverse` : ``)}>
        {props.bag &&
          props.bag.contentItems &&
          props.bag.contentItems.map((item, index) => (
            <EasySoftwareTabsItem key={item?.contentItemId} data={item} index={index} isOpen={index === tabsOpen ? true : false} setTabsOpen={setTabsOpen} />
          ))}
      </div>
    </div>
  )
}

export default EasySoftwareTabs
