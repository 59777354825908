import React from 'react'

import { keyframes } from '@emotion/react'
// import Slide from 'react-awesome-reveal'
import Reveal from 'react-awesome-reveal'

export function getAnimation(animationType: string) {
    switch (animationType) {
        case `fade-in`:
            return keyframes`
          from {
            opacity: 0;
          }
          
          to {
            opacity: 1;
          }
        `
  
        case `fade-from-top`:
            return keyframes`
          from {
            opacity: 0;
            transform: translate(0, -200px);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `
  
        case `fade-from-left`:
            return keyframes`
          from {
            opacity: 0;
            transform: translate(-200px, 0);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `
  
        case `fade-from-right`:
            return keyframes`
          from {
            opacity: 0;
            transform: translate(200px, 0);
          }
          
          to {
            opacity: 1;
            transform: translate(0, 0);
          }
        `
  
        default:
            return null
    }
}

export function getAnimationWrapper(widgetChildren, isEnabled, widgetAnimation) {
    if (isEnabled && widgetAnimation) {
      return (
        <Reveal
          keyframes={widgetAnimation}
          triggerOnce
          fraction={0}
          duration={500}
          delay={150}
          style={{
            transitionTimingFunction: `cubic-bezier(.25,.46,.45,.94)`,
            animationFillMode: `backwards`,
          }}
        >
          {widgetChildren}
        </Reveal>
      )
    } else {
      return widgetChildren
    }
}