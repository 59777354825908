import React, { FunctionComponent, useEffect } from 'react'

import EasySoftwareLottie from '../content/mediaAnimation/EasySoftwareLottie'
import EasySoftwareBackgroundVideo from '../content/mediaBackgroundVideo/EasySoftwareBackgroundVideo'
import PuxMediaSelector from '../content/mediaImage/PuxMediaSelector'
import PuxVideo from '../PuxVideo/PuxVideo'
import { EasySoftwareAccordionItemProps } from './EasySoftwareAccordionItemHeading'

const EasySoftwareAccordionItem: FunctionComponent<EasySoftwareAccordionItemProps> = (
  props
) => {
  const media = props.data?.accordionItemMedia?.cultureContentItems?.length > 0 ? props.data.accordionItemMedia.cultureContentItems[0] : null
  const image = props.data.puxMediaSelector

  function returnMedia() {
    switch (media.contentType) {
      case `EasySoftwareLottie`:
        return (
          <EasySoftwareLottie
            lottieImage={media.lottieImage}
            animationData={JSON.parse(media.lottieJSON)}
            animationDataMobile={media.lottieJSONMobile ? JSON.parse(media.lottieJSONMobile) : null}
          />
        );
      case `EasySoftwareHeroBannerBackground`:
        return (
          <PuxMediaSelector
            {...media.heroBannerBackgroundImage}
            width={800}
            height={470}
            disableLazyload={true}
          />
        );
      case `EasySoftwareBackgroundVideo`:
        return (
          <EasySoftwareBackgroundVideo
            {...media}
          />
        );
      case `PuxVideo`:
        return (
          <PuxVideo
            videoStructuredDataContentUrl={
              media.puxVideo.videoStructuredDataContentUrl
            }
            videoStructuredDataDescription={
              media.puxVideo.videoStructuredDataDescription
            }
            videoStructuredDataName={
              media.puxVideo.videoStructuredDataName
            }
            videoStructuredDataUploadDate={
              media.puxVideo.videoStructuredDataUploadDate
            }
            videoThumbnail={media.puxVideo.videoThumbnail}
            videoYTID={media.puxVideo.videoYTID}
          />
        );

      default:
        return ``;
    }
  }

  function returnImage() {
    return (
      <PuxMediaSelector
        {...image}
        width={720}
        height={400}
      />
    )
  }

  useEffect(() => {
    if (props.isOpen) {
      const video: HTMLVideoElement | null = document.querySelector(`.Accordion-item.active video`) ?? null;
      if (video) {
        video.pause();
        video.currentTime = 0;
        video.load();
      }
    }
  }, [props.isOpen])

  return (
    <div className={`Accordion-item ${props.isOpen ? `active` : ``}`}>
      <div className="Accordion-media">
        <div className="Accordion-media-container">
          {media && returnMedia()}
          {!media && image ? returnImage() : null}
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareAccordionItem
