/* eslint-disable prettier/prettier */
import { useLocation } from '@reach/router'
import PuxLink from 'Shared/utils/PuxLink'
import { decode } from 'html-entities'
import React, { FunctionComponent } from 'react'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import { PuxAnchorLink } from 'Shared/utils/PuxAnchorLink/PuxAnchorLink'

export interface MenuItem {
  linkMenuItem: {
    name: string
    url: string
  }
}
export interface HeaderData {
  menuItems: [MenuItem]
}
interface HeaderNavProps {
  headerData: HeaderData
}
export interface renderData {
  url: string
  name: string
}

const renderTopMenuItem = (item: MenuItem, index: number) => {
  const location = useLocation()
  const pathname = location.pathname

  return (
    <li
      key={index}
    >
      {item.linkMenuItem.url.includes(`#`) && (
        <PuxAnchorLink
          to={`${pathname}${item.linkMenuItem.url}`}
        >
          {decode(item.linkMenuItem.name)}
        </PuxAnchorLink>
      )}
      {!item.linkMenuItem.url.includes(`#`) && (
        <PuxLink
          activeClassName='is-active'
          partiallyActive={true}
          to={`${getLocalizedUrl(item.linkMenuItem.url)}`}
        >
          {decode(item.linkMenuItem.name)}
        </PuxLink>
      )}
    </li>
  )
}

const HeaderMobileNav: FunctionComponent<HeaderNavProps> = (props) => {
  return (
    <nav className={`Header-menu Header-menuRespo`}>
      <ul>
        {props?.headerData?.menuItems?.map((item, index) => renderTopMenuItem(item, index))}
      </ul>
    </nav>
  )
}

export default HeaderMobileNav
