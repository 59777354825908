import './FaqPart.scss'

import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface FaqPartType {
  contentType: 'EasySoftwareFaq'
  contentItemId: string
  displayText: string
  createdUtc: string
  faqAnswer: PuxWysiwygHtmlBodyType
}

const FaqPart: FunctionComponent<FaqPartType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div className='pux-repeater-item FaqPart-wrapper'>
      <div className='FaqPart-item'>
        <div
          className={`FaqPart-question${isOpened ? ` is-opened` : ``}`}
          onClick={() => setIsOpened(!isOpened)}
        >
          <div className='FaqPart-dropdown'>{props.displayText}</div>
        </div>
        <Collapse isOpened={isOpened}>
          <div className='FaqPart-answer'>
            <PuxWysiwyg content={props.faqAnswer} inline />
          </div>
        </Collapse>
      </div>
    </div>
  )
}

export default FaqPart
