import '../EasySoftwareHeroBannerContainer/EasySoftwareHeroBannerContainer.scss'
import '../EasySoftwareHeroBannerContainer/EasySoftwareHeroBannerItem.scss'
import './PuxTopImage.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from '../PuxButton/PuxButton'
import PuxVideo, { PuxVideoType } from '../PuxVideo/PuxVideo'

export enum TopImageContentAlign {
  LEFT = `left`,
  CENTER = `center`,
}

export enum TopImageContentColor {
  DEFAULT = `none`,
  WHITE = `white`,
}

export enum TopImageImageStyle {
  BACKGROUND_GRADIENT = `background-gradient`,
  BACKGROUND = `background`,
  INSIDE = `inside`,
}

export interface PuxTopImageType {
  contentItemId?: string
  displayText: string
  topImageContent: PuxWysiwygHtmlBodyType
  topImageContentAlign: TopImageContentAlign
  topImageContentColor: TopImageContentColor
  topImageBenefit1: PuxWysiwygHtmlBodyType
  topImageBenefit2: PuxWysiwygHtmlBodyType
  topImageImageStyle: TopImageImageStyle
  topImageButton: PuxButtonType
  topImageSecondaryButton: PuxButtonType
  topImageBackground: PuxMediaSelectorType
  puxVideo: PuxVideoType
  topImageButtonAlign: string
}

const PuxTopImage: FunctionComponent<PuxTopImageType> = (props) => {
  let contentColorClass: string
  switch (props.topImageContentColor ?? TopImageContentColor.DEFAULT) {
    case TopImageContentColor.WHITE:
      contentColorClass = `HeroBannerContainer-white`
      break
    default:
      contentColorClass = ``
      break
  }

  const classes =
    `HeroBannerContainer ` +
    contentColorClass +
    ` HeroBannerContainer-${props.topImageContentAlign} ` +
    ` HeroBannerContainer-Buttons${props.topImageButtonAlign} ` +
    `HeroBannerContainer-${props.topImageImageStyle} ` +
    `OldTopImage`

  const thumbnail =
    props.puxVideo?.videoThumbnail?.resizePaths?.length > 0
      ? props.puxVideo?.videoThumbnail
      : props.topImageBackground?.mediaSelectorImage

  switch (props.topImageImageStyle) {
    case TopImageImageStyle.BACKGROUND:
    case TopImageImageStyle.BACKGROUND_GRADIENT:
      return (
        <div className={classes}>
          <div className='HeroBannerContainer-inner'>
            <div className='HeroBannerItem HeroBannerItem--onlyText'>
              <PuxMediaSelector
                {...props.topImageBackground}
                width={1920}
                height={600}
                disableLazyload={true}
              />
              <div className='HeroBannerItem-text'>
                <PuxWysiwyg content={props.topImageContent} />
                {props.topImageBenefit1?.html && props.topImageBenefit2?.html && (
                  <div className='pux-wysiwyg pux-wysiwyg-spaced'>
                    <ul>
                      {props.topImageBenefit1?.html && (
                        <li>
                          <PuxWysiwyg inline content={props.topImageBenefit1} />
                        </li>
                      )}
                      {props.topImageBenefit2?.html && (
                        <li>
                          <PuxWysiwyg inline content={props.topImageBenefit2} />
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                <div className='HeroBannerItem-buttons'>
                  <PuxButton {...props.topImageButton} />
                  {props.topImageSecondaryButton && (
                    <PuxButton {...props.topImageSecondaryButton} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    case TopImageImageStyle.INSIDE:
      return (
        <div className={classes}>
          <div className='HeroBannerContainer-inner'>
            <div className='HeroBannerItem HeroBannerItem--desktopReversed'>
              <div className='HeroBannerItem-image'>
                {!props.puxVideo?.videoYTID?.html && (
                  <PuxMediaSelector
                    {...props.topImageBackground}
                    width={700}
                    height={400}
                    disableLazyload={true}
                  />
                )}
                {props.puxVideo?.videoYTID?.html && (
                  <PuxVideo
                    videoStructuredDataContentUrl={
                      props.puxVideo?.videoStructuredDataContentUrl
                    }
                    videoStructuredDataDescription={
                      props.puxVideo?.videoStructuredDataDescription
                    }
                    videoStructuredDataName={
                      props.puxVideo?.videoStructuredDataName
                    }
                    videoStructuredDataUploadDate={
                      props.puxVideo?.videoStructuredDataUploadDate
                    }
                    videoThumbnail={thumbnail}
                    videoYTID={props.puxVideo?.videoYTID}
                  />
                )}
              </div>
              <div className='HeroBannerItem-text'>
                <PuxWysiwyg content={props.topImageContent} />
                <div className='HeroBannerItem-buttons'>
                  <PuxButton {...props.topImageButton} />
                  {props.topImageSecondaryButton && (
                    <PuxButton {...props.topImageSecondaryButton} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }
}

export default PuxTopImage
