import './PuxImageBox.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Root/Shared/components/content/mediaImage/PuxMediaSelector'
import PuxButton, { PuxButtonType } from 'Shared/components/PuxButton/PuxButton'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export enum PuxImageBoxLayout {
  VERTICAL = `vertical`,
  HORIZONTAL = `horizontal`,
}

export interface PuxImageBoxType {
  columns?: number
  layout?: PuxImageBoxLayout
  contentItemId: string
  displayText: string
  imageBoxImage: PuxMediaSelectorType
  imageBoxContent: PuxWysiwygHtmlBodyType
  imageBoxButton: PuxButtonType
  imageBoxLightbox?: boolean
}

const DEFAULT_LAYOUT: PuxImageBoxLayout = PuxImageBoxLayout.VERTICAL

const PuxImageBox: FunctionComponent<PuxImageBoxType> = (props) => {
  const layout: PuxImageBoxLayout = props.layout ? props.layout : DEFAULT_LAYOUT

  return (
    <div className={`ImageBox ImageBox--${layout}`}>
      {props.imageBoxImage?.mediaSelectorImage?.resizePaths?.length > 0 && (
        <div className='ImageBox-image'>
          <PuxMediaSelector {...props.imageBoxImage} height={150} width={150} />
        </div>
      )}

      <div className='ImageBox-text'>
        {(() => {
          if (props.displayText && props.displayText.length) {
            return <h5 className='ImageBox-title'>{props.displayText}</h5>
          }
        })()}

        <div className='ImageBox-content'>
          <PuxWysiwyg content={props.imageBoxContent} />
        </div>
        <PuxButton {...props.imageBoxButton} />
      </div>
    </div>
  )
}

export default PuxImageBox
