/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

import { getCustomFragment } from '../fragments/widget-fragments'

const SIDE_CARD_CONTENT_TYPE = `EasySoftwareSideCard`
const SIDE_CARD_CONTENT_FIELD = `PuxCategory`

const getSideCardWidgets = async (nodeTermContentItemId, locale, preferedContentLinkCulture, tokenData) => {
  var sideCardWidgets = []
  const taxonomyQuery = `{
          taxonomyContentItem(
            where: {culture: "${locale}", termContentItemId: "${nodeTermContentItemId}", contentType: "${SIDE_CARD_CONTENT_TYPE}", contentField: "${SIDE_CARD_CONTENT_FIELD}"}
            ) {
              contentItemId
            }
        }`

  return await axios
    .post(
      `${process.env.GATSBY_API_URL}`,
      `${taxonomyQuery}`.replace(/\n/g, ``),
      {
        headers: {
          'Content-Type': `application/graphql`,
          'Access-Control-Allow-Origin': `*`,
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then(async (response) => {
      const taxonomyNodes = response.data

      if (!taxonomyNodes || !taxonomyNodes.data.taxonomyContentItem) {
        return sideCardWidgets
      }

      const taxonomyNodeContentItem = taxonomyNodes.data.taxonomyContentItem[0]

      if (!taxonomyNodeContentItem) {
        return sideCardWidgets
      }

      const taxonomyNodeContentItemId = taxonomyNodeContentItem.contentItemId
      const sideCardQuery = `{
          easySoftwareSideCard (
            where: {contentItemId: "${taxonomyNodeContentItemId}"}
          ) {
            widgetZone {
              widgets {
                contentType
                contentItemId
                %widgetFragmentsPlaceholder%
              }
            }
          }
      }`

      await axios
        .post(
          `${process.env.GATSBY_API_URL}`,
          `query ${sideCardQuery
            .replace(/%widgetFragmentsPlaceholder%/g, ``)
            .replace(/\n/g, ``)}`,
          {
            headers: {
              'Content-Type': `application/graphql`,
              'Access-Control-Allow-Origin': `*`,
              Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
            },
          }
        )
        .then(async (response) => {
          const sideCardNode = response.data

          if (!sideCardNode || !sideCardNode.data.easySoftwareSideCard[0]) {
            return sideCardWidgets
          }

          await Promise.all(
            sideCardNode.data.easySoftwareSideCard[0].widgetZone.widgets.map(
              async (widget) => {
                return new Promise(async (resolve) => {
                  await axios
                    .post(
                      `${process.env.GATSBY_API_URL}`,
                      `query ${sideCardQuery.replace(`contentType`, ``).replace(
                        /%widgetFragmentsPlaceholder%/g,
                        getCustomFragment(widget.contentType, preferedContentLinkCulture)
                          .replace(/Orchard_/g, ``)
                          .replace(/\n/g, ``)
                      )}`,
                      {
                        headers: {
                          'Content-Type': `application/graphql`,
                          'Access-Control-Allow-Origin': `*`,
                          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
                        },
                      }
                    )
                    .then((response) => {
                      const sideCardWidgetNode = response.data

                      if (
                        sideCardWidgetNode &&
                        sideCardWidgetNode.data.easySoftwareSideCard
                      ) {
                        // todo: do just one request per contenttype
                        sideCardWidgetNode.data.easySoftwareSideCard[0].widgetZone.widgets
                          .filter((responseWidget) => {
                            return (
                              Object.keys(responseWidget).length > 0 &&
                              !sideCardWidgets.some(
                                (x) =>
                                  x.contentItemId ==
                                  responseWidget.contentItemId
                              ) &&
                              responseWidget.contentItemId ==
                              widget.contentItemId
                            )
                          })
                          .map((w) => {
                            resolve(w)
                          })
                      }
                    })
                })
              }
            )
          ).then((widgets) => {
            sideCardWidgets = widgets
          })
        })
    })
    .then(() => {
      return sideCardWidgets
    })
}

export default getSideCardWidgets

export const getSpecificSideCardWidgets = async (
  locale,
  contentItemId,
  preferedContentLinkCulture,
  tokenData
) => {
  var sideCardWidgets = []
  const sideCardQuery = `{
      easySoftwareSideCard (
        where: {localization: {culture: "${locale}"}}
        ) {
          contentItemId
          sideCardContentLink {
            cultureContentItems(culture: "${preferedContentLinkCulture}") {
              contentItemId
            }
          }
          widgetZone {
            widgets {
              contentType
              contentItemId
              %widgetFragmentsPlaceholder%
            }
          }
        }
      }`

  return await axios
    .post(
      `${process.env.GATSBY_API_URL}`,
      `${sideCardQuery}`
        .replace(/\n/g, ``)
        .replace(/Orchard_/g, ``)
        .replace(/%widgetFragmentsPlaceholder%/g, ``),
      {
        headers: {
          'Content-Type': `application/graphql`,
          'Access-Control-Allow-Origin': `*`,
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then(async (response) => {
      const sideCardNode = response.data

      if (sideCardNode.errors) {
        console.log(JSON.stringify(sideCardNode))
        return sideCardWidgets
      }

      if (!sideCardNode || !sideCardNode.data.easySoftwareSideCard[0]) {
        return sideCardWidgets
      }

      const filteredSideCardNode = sideCardNode.data.easySoftwareSideCard.filter(
        (sideCard) => {
          if (sideCard.sideCardContentLink && sideCard.sideCardContentLink.cultureContentItems.length > 0) {
            return (
              sideCard.sideCardContentLink.cultureContentItems[0].contentItemId === contentItemId
            )
          }
          return
        }
      )

      if (!filteredSideCardNode.length) {
        return sideCardWidgets
      }

      const specificSidecardContentItemId =
        filteredSideCardNode[0].contentItemId || undefined

      if (!specificSidecardContentItemId) {
        return sideCardWidgets
      }

      await Promise.all(
        filteredSideCardNode[0].widgetZone.widgets.map(async (widget) => {
          return new Promise(async (resolve) => {
            const sideCardWidgetsQuery = `{
              easySoftwareSideCard (
                where: {contentItemId: "${specificSidecardContentItemId}"}
                ) {
                  widgetZone {
                    widgets {
                      contentType
                      %widgetFragmentsPlaceholder%
                    }
                  }
                }
              }`

            await axios
              .post(
                `${process.env.GATSBY_API_URL}`,
                `query ${sideCardWidgetsQuery
                  .replace(`contentType`, ``)
                  .replace(
                    /%widgetFragmentsPlaceholder%/g,
                    getCustomFragment(widget.contentType, preferedContentLinkCulture)
                      .replace(/Orchard_/g, ``)
                      .replace(/\n/g, ``)
                  )}`,
                {
                  headers: {
                    'Content-Type': `application/graphql`,
                    'Access-Control-Allow-Origin': `*`,
                    Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
                  },
                }
              )
              .then((response) => {
                const sideCardWidgetNode = response.data

                if (sideCardWidgetNode.errors) {
                  console.log(JSON.stringify(sideCardNode))
                  return sideCardWidgets
                }

                if (
                  sideCardWidgetNode &&
                  sideCardWidgetNode.data.easySoftwareSideCard
                ) {
                  sideCardWidgetNode.data.easySoftwareSideCard[0].widgetZone.widgets
                    // todo: do just one request per contenttype
                    .filter((responseWidget) => {
                      return (
                        Object.keys(responseWidget).length > 0 &&
                        !sideCardWidgets.some(
                          (x) => x.contentItemId == responseWidget.contentItemId
                        ) &&
                        responseWidget.contentItemId == widget.contentItemId
                      )
                    })
                    .map((w) => {
                      resolve(w)
                    })
                }
              })
          })
        })
      ).then((widgets) => {
        sideCardWidgets = widgets
      })
    })
    .then(() => {
      return sideCardWidgets
    })
}
