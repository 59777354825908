import './PartnerPart.scss'

import React, { FunctionComponent } from 'react'
import PuxMediaSelector, {
  PuxMediaSelectorType,
} from 'Shared/components/content/mediaImage/PuxMediaSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { dt } from 'Shared/utils/dynamicRS'

export interface PartnerPartType {
  contentType: 'EasySoftwarePartner'
  contentItemId: string
  displayText: string
  createdUtc: string
  partnerCountry: string
  partnerLevel: string
  partnerLogo: PuxMediaSelectorType
  partnerText: PuxWysiwygHtmlBodyType
  partnerWebsiteUrl: {
    text: string
    url: string
  }
}

const PartnerPart: FunctionComponent<PartnerPartType> = (props) => {
  return (
    <div className='pux-repeater-item PartnerPartwrapper'>
      <div className='PartnerPart-item'>
        <a
          className='PartnerPart-image'
          target={`_blank`}
          href={props.partnerWebsiteUrl.url}
        >
          <PuxMediaSelector {...props.partnerLogo} height={75} width={364} />
        </a>

        <h5 className='PartnerPart-heading'>
          <a href={props.partnerWebsiteUrl.url}>{props.displayText}</a>
        </h5>
        <div className='PartnerPart-content'>
          <div className='PartnerPart-country'>{dt(props.partnerCountry)}</div>
          <div className='PartnerPart-level'>
            {`${
              props.partnerLevel.charAt(0).toUpperCase() +
              props.partnerLevel.slice(1)
            }`}
          </div>
        </div>

        <div className='PartnerPart-perex'>
          <PuxWysiwyg content={props.partnerText} inline />
        </div>
      </div>
    </div>
  )
}

export default PartnerPart
