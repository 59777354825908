import React, { useEffect, useRef } from "react";
import Lottie from 'lottie-react';
import useWindowDimensions from "Root/Shared/utils/useWindowDimensions";
import PuxMediaSelector from "../mediaImage/PuxMediaSelector";
import { MediaSelectorImageType } from "../mediaImage/PuxPicture";

type EasySoftwareLottieProps = {
  animationData: string,
  animationDataMobile: string | null,
  lottieImage: MediaSelectorImageType | null
}

function EasySoftwareLottie({ animationData, animationDataMobile, lottieImage }: EasySoftwareLottieProps) {

  const animation = useRef(null);
  const animationMobile = useRef(null);
  const { width } = useWindowDimensions()

  useEffect(() => {
    animationDataMobile && width < 992 && animationMobile?.current?.play();
    width >= 992 && animation?.current?.play();
  }, [])

  return (
    <>
      {lottieImage && !animationDataMobile &&
        <div className="lottie-placeholder">
          <PuxMediaSelector
            mediaSelectorImage={lottieImage}
            width={500}
            height={200}
          />
        </div>
      }
      {animationDataMobile &&
        <div className="lottie lottie-mobile">
          <Lottie
            animationData={animationDataMobile}
            lottieRef={animationMobile}
            loop={false}
            rendererSettings={{
              preserveAspectRatio: "xMidYMid slice"
            }}
          />
        </div>
      }
      <div className={`lottie lottie-desktop` + (!animationDataMobile ? ` lottie-mobile` : ``)}>
        <Lottie
          animationData={animationData}
          lottieRef={animation}
          loop={false}
          rendererSettings={{
            preserveAspectRatio: "xMidYMid slice"
          }}
        />
      </div>
    </>
  )
}

export default EasySoftwareLottie