import './EasySoftwareTable.scss'

import React, { FunctionComponent, useEffect, useRef } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface EasySoftwareTableType {
  displayText: string
  tableContent: PuxWysiwygHtmlBodyType
}

const EasySoftwareTable: FunctionComponent<EasySoftwareTableType> = (props) => {
  const container = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)
  let scrollLeft = true
  let scrollable = false
  const scrollClass = `Table-content-container--with-scroll`
  const scrollArrowClass = `Table-content-container--with-arrow`

  useEffect(() => {
    if (container.current != null && content.current != null) {
      scrollable = container.current.scrollWidth != content.current.offsetWidth
      scrollable
        ? container.current.classList.add(scrollClass, scrollArrowClass)
        : null
    }
  })

  const scrollFunction = () => {
    if (container.current != null) {
      scrollLeft =
        container.current.scrollWidth - container.current.scrollLeft >=
        container.current.offsetWidth + 1
      if (scrollLeft) {
        container.current.classList.add(scrollArrowClass)
      } else {
        container.current.classList.remove(scrollArrowClass)
      }
    }
  }

  return (
    <div className='Table'>
      {props.displayText && (
        <h2 className='Table-title'>{props.displayText}</h2>
      )}
      <div
        ref={container}
        onScroll={scrollFunction}
        className={`Table-content-container`}
      >
        <div ref={content} className='Table-content'>
          <PuxWysiwyg content={props.tableContent} />
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareTable
